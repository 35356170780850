import React, { useContext, useState } from 'react'

import {
  Button,
  Group as MantineGroup,
  LoadingOverlay,
  Stepper,
  Text,
  Tooltip
} from '@mantine/core'

import SectionContext from '@form/Context/Section.context'
import { SectionStep } from '../SectionStep'
import { storage } from '@utils/storage'
import { baseApiURL } from '@utils/UrlHelper'
import { getItem } from '../../../services/cache'
import { useNavigate } from 'react-router'
import { Sections } from './Sections'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { useDisclaimerContext } from '../../Context/Disclaimer.context'
import { appendPathToURL, reactPlatformURL } from 'utils'

export const SectionNav = ({ steps, stepSize, buildReviewSection }) => {
  const { currentStep, dealId, setCurrentStep } = useContext(SectionContext)
  const {
    disclaimer: { agree_required: agreeRequired },
    agreedToDisclaimerTerms
  } = useDisclaimerContext()

  const [active, setActive] = useState(() => Number(storage.get('currentStep')))
  const [valid, setValid] = useState(true)
  const nextStep = () => {
    setActive((current) => {
      const currentStep = current < stepSize + 1 ? current + 1 : current
      setCurrentStep(currentStep)
      return currentStep
    })
  }
  const prevStep = () => {
    setActive((current) => {
      const currentStep = current > 0 ? current - 1 : current
      setCurrentStep(currentStep)
      return currentStep
    })
  }
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  // TODO: Finish implementing error handling
  // const [error, setError] = useState(false);

  const getFormData = async () => await getItem(`${dealId}_form`)

  const handleStepClick = (step) => {
    if (step <= currentStep) {
      setActive(step)
    }
  }

  const setStep = (step) => {
    setActive(step)
    setCurrentStep(step)
  }

  const submitHandler = async () => {
    setValid(false)
    setIsSubmitting(true)
    const formData = await getFormData()
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        deal: formData?.deal[0],
        identifier: storage.get('identifier')
      })
    }

    fetch(`${baseApiURL()}/api/v1/deals/${formData?.deal[0]?.id}/submit`, requestOptions).then(
      async (response) => {
        setIsSubmitting(false)

        if (!response.ok) {
          navigate('/error')
          const json = await response.json()
          console.error(json)
        } else {
          storage.remove('currentStep')
          window.location.assign(
            appendPathToURL(reactPlatformURL(), '/prospects/dashboard?welcome=true')
          )
        }
      }
    )
  }

  const hasNotagreedToDisclaimerTerms = agreeRequired && !agreedToDisclaimerTerms
  const handleSubmit = () => {
    void submitHandler()
  }

  return (
    <Stepper active={active} breakpoint="sm" onStepClick={handleStepClick} color="secondary">
      {steps.map((step) => {
        return (
          <Stepper.Step label={step.name} key={step.name}>
            <SectionStep
              step={step}
              stepSize={stepSize}
              nextStep={nextStep}
              prevStep={prevStep}
              currentStep={currentStep}
              key={step.name}
            />
          </Stepper.Step>
        )
      })}
      <Stepper.Completed>
        <LoadingOverlay visible={isSubmitting} overlayBlur={2} />
        <Text>Please review your submission:</Text>
        {!isSubmitting && active === stepSize && (
          <Sections buildReviewSection={buildReviewSection} setStep={setStep} />
        )}
        <MantineGroup position="center" my="2rem" spacing="xl">
          <Button
            onClick={() => {
              setStep(0)
            }}
            styles={(theme) => ({
              root: {
                backgroundColor: 'white',
                color: theme.colors.gray[8],
                border: '1px solid currentColor',
                '&:hover': {
                  backgroundColor: theme.colors.gray[1]
                }
              },
              label: {
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing.xs
              }
            })}
            size="lg"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
            <span>Edit</span>
          </Button>
          {hasNotagreedToDisclaimerTerms ? (
            <Tooltip
              label="Choose 'I Agree' to submit the application"
              events={{ hover: true, focus: true, touch: true }}
            >
              <Button
                onClick={(event) => event.preventDefault()}
                data-disabled
                sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                size="lg"
              >
                Submit
              </Button>
            </Tooltip>
          ) : (
            <Button onClick={handleSubmit} disabled={!valid} size="lg">
              Submit
            </Button>
          )}
        </MantineGroup>
      </Stepper.Completed>
    </Stepper>
  )
}
